import React from 'react';

import { Box } from '@material-ui/core';

const page = `
<h1>S&uuml;tik (Cookie) kezel&eacute;se</h1>
<p>
  1.) Milyen inform&aacute;ci&oacute;kat gyűjt&uuml;nk a Weboldal
  haszn&aacute;lat&aacute;val &ouml;sszef&uuml;gg&eacute;sben?
</p>
<p>
  Amennyiben a Felhaszn&aacute;l&oacute; a Weboldalon az I. r&eacute;szben
  &iacute;rtak szerint kifejezetten nem ad meg saj&aacute;t mag&aacute;ra
  vonatkoz&oacute; adatot, inform&aacute;ci&oacute;t, &uacute;gy az Adatkezelő
  semmilyen, a Felhaszn&aacute;l&oacute;ra vonatkoz&oacute; szem&eacute;lyes
  adatot nem gyűjt vagy kezel olyan m&oacute;don, amely alapj&aacute;n a
  Felhaszn&aacute;l&oacute; szem&eacute;ly szerint beazonos&iacute;that&oacute;
  lenne.
</p>
<p>
  A Weboldalra l&aacute;togat&aacute;ssal, valamint az &bdquo;Elfogadom&rdquo;
  gomb megnyom&aacute;s&aacute;val valamennyi Felhaszn&aacute;l&oacute;
  hozz&aacute;j&aacute;rul ahhoz, hogy az Adatkezelő a
  T&aacute;j&eacute;koztat&oacute; jelen II. r&eacute;sz&eacute;ben &iacute;rt
  adatok, inform&aacute;ci&oacute;k r&ouml;gz&iacute;t&eacute;s&eacute;hez
  sz&uuml;ks&eacute;ges k&uuml;lső szolg&aacute;ltat&oacute;k &aacute;ltal
  kezelt cookie-kat haszn&aacute;ljon a Weboldallal
  &ouml;sszef&uuml;gg&eacute;sben.
</p>
<p>
  Ilyen adatok a Felhaszn&aacute;l&oacute; bejelentkező
  sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;nek azon adatai, amelyek a
  Weboldal haszn&aacute;lata sor&aacute;n gener&aacute;l&oacute;dnak &eacute;s,
  amelyeket a Weboldalon haszn&aacute;lt cookie-k a technikai folyamatok
  automatikus eredm&eacute;nyek&eacute;nt r&ouml;gz&iacute;tenek. Az
  automatikusan r&ouml;gz&iacute;t&eacute;sre ker&uuml;lő adatokat a rendszer
  &ndash; a Felhaszn&aacute;l&oacute; k&uuml;l&ouml;n nyilatkozata vagy
  cselekm&eacute;nye n&eacute;lk&uuml;l &ndash; a Weboldalra
  l&aacute;togat&aacute;skor, illetve kil&eacute;p&eacute;skor automatikusan
  napl&oacute;zza.
</p>
<p>
  Ezen adatok egy&eacute;b szem&eacute;lyes felhaszn&aacute;l&oacute;i adatokkal
  nem ker&uuml;lnek &ouml;sszekapcsol&aacute;sra, azaz a
  Felhaszn&aacute;l&oacute; ezen adatok alapj&aacute;n nem
  beazonos&iacute;that&oacute;. Az ilyen adatokhoz kiz&aacute;r&oacute;lag a
  cookie-kat kezelő k&uuml;lső szolg&aacute;ltat&oacute;k &eacute;s az
  Adatkezelő f&eacute;r hozz&aacute;.
</p>
<p>
  Adatkezelő a Weboldalon kiz&aacute;r&oacute;lag k&uuml;lső
  szolg&aacute;ltat&oacute;k (pl. Google) &aacute;ltal kezelt cookie-kat
  haszn&aacute;l. A cookie-k r&ouml;vid sz&ouml;veg file-ok, amelyeket a
  Weboldal k&uuml;ld a Felhaszn&aacute;l&oacute;
  sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;nek merevlemez&eacute;re,
  &eacute;s a Felhaszn&aacute;l&oacute;ra vonatkoz&oacute;
  inform&aacute;ci&oacute;kat tartalmaznak.
</p>
<p>
  Az Adatkezelő a Weboldallal &ouml;sszef&uuml;gg&eacute;sben ig&eacute;nybe
  veszi a Google Analytics rendszer szolg&aacute;ltat&aacute;sait. A Google
  Analytics &aacute;ltal kezelt cookie-k a Weboldalra
  l&aacute;togatotts&aacute;gi &eacute;s egy&eacute;b webanalitikai adatok
  m&eacute;r&eacute;s&eacute;t seg&iacute;tik. A cookie-k &aacute;ltal
  gyűjt&ouml;tt inform&aacute;ci&oacute;k a Google &aacute;ltal
  &uuml;zemeltetett k&uuml;lső szerverekre ker&uuml;lnek
  tov&aacute;bb&iacute;t&aacute;sra &eacute;s t&aacute;rol&aacute;sra. A Google
  ezeket az inform&aacute;ci&oacute;kat az Adatkezelő &eacute;rdek&eacute;ben
  elsősorban arra haszn&aacute;lja, hogy nyomon k&ouml;vesse a Weboldal
  l&aacute;togatotts&aacute;g&aacute;t &eacute;s a Weboldalon v&eacute;gzett
  tev&eacute;kenys&eacute;gekről elemz&eacute;seket k&eacute;sz&iacute;tsen. A
  Google ezeket az inform&aacute;ci&oacute;kat jogosult harmadik
  szem&eacute;lyek r&eacute;sz&eacute;re tov&aacute;bb&iacute;tani, amennyiben
  ezt jogszab&aacute;ly k&ouml;telezőv&eacute; teszi. A Google jogosult
  tov&aacute;bb&aacute; ezeket az adatokat az &aacute;ltala az adatok
  feldolgoz&aacute;s&aacute;hoz ig&eacute;nybe vett harmadik szem&eacute;lyek
  r&eacute;sz&eacute;re is tov&aacute;bb&iacute;tani. Az adatok Google Analytics
  &aacute;ltali kezel&eacute;s&eacute;ről a Google Analytics tud
  r&eacute;szletes felvil&aacute;gos&iacute;t&aacute;st adni
  (http://www.google.com/analytics).
</p>
<p>
  Az Adatkezelő hirdet&eacute;seit k&uuml;lső szolg&aacute;ltat&oacute;k
  (Google, Facebook) internetes webhelyeken jelen&iacute;tik meg. Ezek a
  k&uuml;lső szolg&aacute;ltat&oacute;k (Google, Facebook) cookie-k
  seg&iacute;ts&eacute;g&eacute;vel t&aacute;rolj&aacute;k, hogy a
  Felhaszn&aacute;l&oacute; kor&aacute;bban m&aacute;r l&aacute;togat&aacute;st
  tett az Adatkezelő Weboldal&aacute;n, &eacute;s ez alapj&aacute;n &ndash;
  szem&eacute;lyre szabottan - jelen&iacute;tik meg a hirdet&eacute;seket a
  Felhaszn&aacute;l&oacute;nak (azaz remarketing tev&eacute;kenys&eacute;get
  folytatnak).
</p>
<p>
  A Google cookie-haszn&aacute;lata letilthat&oacute; a
  Hirdet&eacute;sbe&aacute;ll&iacute;t&aacute;sok (bővebb
  inform&aacute;ci&oacute;: http://www.google.hu/policies/privacy/ads/)
  seg&iacute;ts&eacute;g&eacute;vel. A Felhaszn&aacute;l&oacute;k a Network
  Advertising Initiative (http://www.networkadvertising.org/choices/)
  leiratkoz&aacute;si oldal&aacute;n a k&uuml;lső szolg&aacute;ltat&oacute;k
  cookie-jait is letilthatj&aacute;k.
</p>
<p>
  A fent eml&iacute;tett k&uuml;lső szolg&aacute;ltat&oacute;k
  adatkezel&eacute;s&eacute;re az ezen szolg&aacute;ltat&oacute;k &aacute;ltal
  meghat&aacute;rozott adatv&eacute;delmi elő&iacute;r&aacute;sok az
  ir&aacute;nyad&oacute;ak, &eacute;s az ilyen adatkezel&eacute;s
  tekintet&eacute;ben az Adatkezelő semmilyen felelőss&eacute;get nem
  v&aacute;llal.
</p>
<p>2.) Hogyan haszn&aacute;ljuk ezeket az inform&aacute;ci&oacute;kat?</p>
<p>
  A fent-eml&iacute;tett technol&oacute;gi&aacute;kkal gyűjt&ouml;tt adatok nem
  haszn&aacute;lhat&oacute;k fel a Felhaszn&aacute;l&oacute;
  azonos&iacute;t&aacute;s&aacute;ra, &eacute;s az Adatkezelő nem is kapcsolja
  &ouml;ssze ezen adatokat semmilyen egy&eacute;b, esetlegesen
  azonos&iacute;t&aacute;sra alkalmas adattal.
</p>
<p>
  Az ilyen adatok felhaszn&aacute;l&aacute;s&aacute;nak elsődleges c&eacute;lja,
  hogy az Adatkezelő a Weboldalt megfelelően műk&ouml;dtetni tudja, amelyhez
  sz&uuml;ks&eacute;ges k&uuml;l&ouml;n&ouml;sen a Weboldalra
  l&aacute;togat&aacute;si adatok nyomon k&ouml;vet&eacute;se, &eacute;s a
  Weboldal haszn&aacute;lattal kapcsolatos esetleges vissza&eacute;l&eacute;sek
  kiszűr&eacute;se.
</p>
<p>
  Az Adatkezelő a fentieken k&iacute;v&uuml;l felhaszn&aacute;lhatja ezen
  inform&aacute;ci&oacute;kat arra, hogy felhaszn&aacute;l&aacute;si
  tendenci&aacute;kat elemezzen, illetve a Weboldal funkci&oacute;it
  jav&iacute;tsa, fejlessze, tov&aacute;bb&aacute;, hogy &aacute;tfog&oacute;
  forgalmi adatokat szerezzen a Weboldal teljes haszn&aacute;latr&oacute;l.
</p>
<p>
  Az Adatkezelő felhaszn&aacute;lhatja az &iacute;gy nyert
  inform&aacute;ci&oacute;kat arra, hogy a Weboldal haszn&aacute;lat&aacute;val
  kapcsolatban statisztik&aacute;kat &aacute;ll&iacute;tson fel, illetve
  elemezzen, valamint, hogy az ilyen azonos&iacute;t&aacute;sra nem alkalmas
  statisztikai adatokat (pl.: l&aacute;togat&oacute;k sz&aacute;ma,
  legn&eacute;zettebb t&eacute;mak&ouml;r&ouml;k, illetve tartalmak) harmadik
  szem&eacute;ly r&eacute;sz&eacute;re tov&aacute;bb&iacute;tson, illetve
  &ouml;sszes&iacute;tve, anonim m&oacute;don nyilv&aacute;noss&aacute;gra
  hozzon.
</p>
<p>3.) Lehetős&eacute;g a Cookie-k kikapcsol&aacute;s&aacute;ra:</p>
<p>
  Amennyiben nem szeretn&eacute;, hogy a fentiekben &iacute;rt
  inform&aacute;ci&oacute;kat gyűjtsenek &Ouml;nről a Weboldal
  haszn&aacute;lat&aacute;val &ouml;sszef&uuml;gg&eacute;sben, az
  internetb&ouml;ng&eacute;sző be&aacute;ll&iacute;t&aacute;saiban
  r&eacute;szben vagy eg&eacute;szben kikapcsolhatja a cookie-k
  haszn&aacute;lat&aacute;t, vagy egy&eacute;bk&eacute;nt
  m&oacute;dos&iacute;thatja a cookie &uuml;zenetek
  be&aacute;ll&iacute;t&aacute;sait.
</p>
<p>4.) Harmadik szem&eacute;lyek &aacute;ltal elhelyezett cookie-k:</p>
<p>
  A Weboldal tartalmazhat olyan inform&aacute;ci&oacute;kat,
  k&uuml;l&ouml;n&ouml;sen rekl&aacute;mokat, amelyek olyan harmadik
  szem&eacute;lyektől, rekl&aacute;mszolg&aacute;ltat&oacute;kt&oacute;l
  sz&aacute;rmaznak, akik nem &aacute;llnak kapcsolatban a barbercloud.net
  weboldallal. Előfordulhat, hogy ezen harmadik szem&eacute;lyek is elhelyeznek
  cookie-kat, web beacon-okat a felhaszn&aacute;l&oacute;
  sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;n, vagy hasonl&oacute;
  technol&oacute;gi&aacute;kat alkalmazva gyűjtenek adatokat annak
  &eacute;rdek&eacute;ben, hogy a felhaszn&aacute;l&oacute;nak a saj&aacute;t
  szolg&aacute;ltat&aacute;saikkal &ouml;sszef&uuml;gg&eacute;sben
  c&iacute;mzett rekl&aacute;m&uuml;zenetet k&uuml;ldjenek. Ilyen esetekben az
  adatkezel&eacute;sre az ezen harmadik szem&eacute;lyek &aacute;ltal
  meghat&aacute;rozott adatv&eacute;delmi elő&iacute;r&aacute;sok az
  ir&aacute;nyad&oacute;ak, &eacute;s az ilyen adatkezel&eacute;s
  tekintet&eacute;ben az Adatkezelő semmilyen felelőss&eacute;get nem
  v&aacute;llal.
</p>
`;

// const english = `
// <p>Angol:</p>
// <p>COOKIE POLICY</p>
// <p>Last updated: 2020.02.01</p>
// <p>
//   Barbercloud Ltd. ("us", "we", or "our") uses cookies on www.barbercloud.net
//   (the "Service"). By using the Service, you consent to the use of cookies.
// </p>
// <p>
//   Our Cookies Policy explains what cookies are, how we use cookies, how
//   third-parties we may partner with may use cookies on the Service, your choices
//   regarding cookies and further information about cookies.
// </p>
// <p>What are cookies</p>
// <p>
//   Cookies are small pieces of text sent by your web browser by a website you
//   visit. A cookie file is stored in your web browser and allows the Service or a
//   third-party to recognize you and make your next visit easier and the Service
//   more useful to you.
// </p>
// <p>Cookies can be "persistent" or "session" cookies.</p>
// <p>How Barbercloud uses cookies</p>
// <p>
//   When you use and access the Service, we may place a number of cookies files in
//   your web browser.
// </p>
// <p>
//   We use cookies for the following purposes: to enable certain functions of the
//   Service, to provide analytics, to store your preferences, to enable
//   advertisements delivery, including behavioral advertising.
// </p>
// <p>
//   We use both session and persistent cookies on the Service and we use different
//   types of cookies to run the Service:
// </p>
// <p>
//   Essential cookies. We may use essential cookies to authenticate users and
//   prevent fraudulent use of user accounts.<br />Third-party cookies
// </p>
// <p>
//   In addition to our own cookies, we may also use various third-parties cookies
//   to report usage statistics of the Service, deliver advertisements on and
//   through the Service, and so on.
// </p>
// <p>What are your choices regarding cookies</p>
// <p>
//   If you'd like to delete cookies or instruct your web browser to delete or
//   refuse cookies, please visit the help pages of your web browser.
// </p>
// <p>
//   Please note, however, that if you delete cookies or refuse to accept them, you
//   might not be able to use all of the features we offer, you may not be able to
//   store your preferences, and some of our pages might not display properly.
// </p>
// `;

const cookiePolicy = () => (
  <Box p={2}>
    <div dangerouslySetInnerHTML={{ __html: page }} />
  </Box>
);

export default cookiePolicy;
